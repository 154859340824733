.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* PowerBI */
#reportContainer {
	background: #eaeaea;
	color: #6e6e6e;
	font: Lighter 40px Segoe UI;
	height: calc(0.5625 * 65vw); /* 16:9 aspect ratio */
	min-width: 800px;
	text-align: center;
	width: 100%;
}

@media only screen and (max-width: 1333.33px) {
	#reportContainer {
		height: calc(0.5625 * 870px); /* 16:9 aspect ratio */
	}
}

iframe {
	border: none;
}

aside.ps-sidebar-root {
	border-color: #504D82;
}
